import Image from "next/legacy/image"
import Link from "next/link"
import styles from "./marking-area.module.css"
import Arrow from "@/common/icons/arrow"

export default function Card({ post }) {
  if (!post) {
    return null
  }
  const { icon, heading, label, lead, url } = post
  return (
    <Link href={url} prefetch={false}>
      <div className={styles.card}>
        <div className="mx-5 mt-5 mb-9 md:mb-7.5 flex-auto">
          <div className={styles.icon}>
            <figure className="relative w-full h-full">
              {post.icon && (
                <Image
                  src={icon}
                  layout="fill"
                  objectFit="contain"
                  objectPosition="center"
                />
              )}
            </figure>
          </div>

          <h4 className="heading">{heading}</h4>
          <p className="text-base text leading-6.5">{lead}</p>
        </div>
        <footer>
          <span>{label}</span>
          <div className="icon-btn bg-good-green-50 text-ink-100">
            <Arrow className="w-4 h-4 mx-auto" />
          </div>
        </footer>
      </div>
    </Link>
  )
}
