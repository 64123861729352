import Image from "next/legacy/image"
import Link from "next/link"
import { useState } from "react"
import HeadingLabel from "@/common/partials/headingLabel/headingLabel"
import styles from "./article.module.css"
import classNames from "classnames"

export default function Article({ post, classes = [], trackClick = () => {} }) {
  const [hovered, setHovered] = useState(false)
  classes = classNames("font-sans", hovered ? styles.hovered : "", styles.card)
  const dateLine = [
    post.meta?.dateline.created,
    post.meta?.dateline.readTime > 0
      ? post.meta.dateline.readTime + " min att läsa"
      : false
  ]

  const PostTag = () => {
    if (post.tag.url === null) {
      return <HeadingLabel>{post.tag.label}</HeadingLabel>
    }

    return (
      <Link href={post.tag.url} prefetch={false} legacyBehavior>
        <a>
          <HeadingLabel>{post.tag.label}</HeadingLabel>
        </a>
      </Link>
    )
  }

  const sizes =
    !post.image || !post.image?.sizes || post.image.sizes.length === 0
      ? "(max-width: 768px) 100vw, (max-width: 1280px) 50vw, 25vw"
      : post.image.sizes

  return (
    <div className={classNames(classes)}>
      <Link href={post.url} prefetch={false} legacyBehavior>
        <a
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={(e) => trackClick(e, post.url)}>
          <div className={styles.imageWrapper}>
            <figure className={styles.image}>
              {post.image && (
                <Image
                  className={styles.image}
                  src={post.image.url}
                  sizes={sizes}
                  alt={post.image.alt}
                  layout="fill"
                  loading="lazy"
                />
              )}
            </figure>
          </div>
        </a>
      </Link>
      <PostTag />
      <Link href={post.url} prefetch={false} legacyBehavior>
        <a
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}>
          <h3 className={styles.headline}>{post.title}</h3>
        </a>
      </Link>
      <div className={styles.byLine}>
        {post.meta?.byline && <span>Av: {post.meta.byline}</span>}
        <span className="block">{dateLine.filter(Boolean).join(" • ")}</span>
      </div>
    </div>
  )
}
